body {
  margin: 0;
}

@font-face {
  font-family: "Pixel";
  src: url("font/Pixel-LCD-7.woff") format("woff");
}

html,
body,
.root,
.App {
  font-family: 'Ubuntu', sans-serif !important;
  overflow-y: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
}

.App {
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;
  max-height: 100%;
  max-height: -moz-available; /* WebKit-based browsers will ignore this. */
  max-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  max-height: fill-available;
}

/* div, p, body, button, label {
  touch-action: manipulation;
} */
